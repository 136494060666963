<template>
  <div class="content-wrapper">
    <bo-page-header></bo-page-header>
    <div class="content pt-0">
      <b-card body-class="d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
        <div class="d-flex align-items-center mb-3 mb-md-0">
          <div class="form-group mb-0">
            <div class="input-group">
              <input type="text" class="form-control border-right-0" placeholder="Search... ">
              <span class="input-group-append">
                <button class="btn bg-indigo-400 " type="button">Search</button>
              </span>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center mb-3 mb-md-0">
          <ul class="nav nav-pills justify-content-end mb-0">
            <li class="nav-item"><a href="#right-pill1" class="nav-link bg-indigo-400 active" data-toggle="tab">Days</a>
            </li>
            <li class="nav-item"><a href="#right-pill2" class="nav-link" data-toggle="tab">Week</a></li>
            <li class="nav-item"><a href="#right-pill2" class="nav-link" data-toggle="tab">Month</a></li>
          </ul>
          <div class="ml-3">
            <div class="input-group">
              <span class="input-group-prepend">
                <span class="input-group-text"><i class="icon-calendar22"></i></span>
              </span>
              <date-range-picker class="form-control" control-container-class="" opens="left" append-to-body
                :date-range="dateRange" style="min-width: 180px" />
            </div>
          </div>
        </div>
      </b-card>
      <b-row cols="1" cols-md="5">
        <b-col>
          <b-card>
            <div class="total_text_crm ">
              <h3 class="text-danger">3,5</h3>
              <p>Average Closing Day</p>
            </div>
          </b-card>
        </b-col>
        <b-col>
          <b-card>
            <div class="total_text_crm">
              <h3 class="text-green">2,5%</h3>
              <p>Closing Convertion</p>
            </div>
          </b-card>
        </b-col>
        <b-col>
          <b-card>
            <div class="total_text_crm">
              <h3 class="text-info">383</h3>
              <p>Average Leads / Week</p>
            </div>
          </b-card>
        </b-col>
        <b-col>
          <b-card>
            <div class="total_text_crm">
              <h3 class="text-green">383</h3>
              <p>Average Leads / Month</p>
            </div>
          </b-card>
        </b-col>
        <b-col>
          <b-card>
            <div class="total_text_crm">
              <h3 class="text-green">Rp 10.000.000</h3>
              <p>Total Project</p>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-card no-body>
        <template #header>
          <b-card-title title-tag="h6" class="font-weight-bold">Summary</b-card-title>
        </template>
        <b-table :fields="leadSummaryFields" :items="leadSummaryDatas" bordered responsive :per-page="10"
          :current-page="leadSummaryPage">
          <template #cell(num)="data">
            {{ data.index += 1 + (leadSummaryPage - 1)*5  }}
          </template>
          <template #cell(leads)="data"><strong>{{ data.value }}</strong></template>
          <template #cell(total)="data"><span class="text-success">{{ data.value }}</span></template>
          <template #cell(increase)="data"><span class="text-danger">{{ data.value }}</span></template>
          <template #cell(conversion)="data"><span class="text-danger">{{ data.value }}</span></template>
        </b-table>
        <template #footer>
          <b-pagination :per-page="5" :total-rows="leadSummaryDatas.length" class="pagination-flat"
            v-model="leadSummaryPage" hide-goto-end-buttons align="end">
            <template #prev-text>&larr; &nbsp; Prev</template>
            <template #next-text>Next &nbsp; &rarr;</template>
          </b-pagination>
        </template>
      </b-card>

      <b-card>
        <template #header>
          <b-row>
            <b-col md="auto">
              <b-card-title title-tag="h6" class="font-weight-bold">Leads</b-card-title>
            </b-col>
            <b-col md="5" class="mx-auto">
              <b-row>
                <b-col md="5">
                  <b-form-select :options="leadOptions" v-model="lead1" size="sm" />
                </b-col>
                <b-col md="auto">
                  <p class="font-weight-bold mb-0 pt-1">VS</p>
                </b-col>
                <b-col md="5">
                  <b-form-select :options="leadOptions" v-model="lead1" size="sm" />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
        <div class="chart-container">
          <v-chart class="chart has-fixed-height" :option="leadReportOptions" />
        </div>
      </b-card>
      <b-card no-body>
        <template #header>
          <b-card-title title-tag="h6" class="font-weight-bold">Closing</b-card-title>
        </template>
        <b-table :fields="closingFields" :items="closingDatas" bordered responsive :per-page="5"
          :current-page="closingPage">
          <template #cell(num)="data">
            {{ data.index += 1 }}
          </template>
          <template #cell(submitted_date)="data">
            {{ data.value | moment("ll") }}
          </template>
          <template #cell(close_date)="data">
            {{ data.value | moment("ll") }}
          </template>
          <template #cell(value)="data">
            <strong>{{ new Intl.NumberFormat('en-ID', { style: 'currency', currency: 'IDR' }).format(data.value) }}</strong>
          </template>
        </b-table>
        <template #footer>
          <b-pagination :per-page="5" :total-rows="closingDatas.length" class="pagination-flat" v-model="closingPage"
            hide-goto-end-buttons align="end">
            <template #prev-text>&larr; &nbsp; Prev</template>
            <template #next-text>Next &nbsp; &rarr;</template>
          </b-pagination>
        </template>
      </b-card>
      <b-card header-class="header-elements-inline" no-body>
        <template #header>
          <b-card-title title-tag="h6" class="font-weight-bold">All Activity</b-card-title>
          <div class="header-elements">
            <b-row>
              <b-col md="auto">
                <b-select size="sm" v-model="selTime" :options="timeOptions" />
              </b-col>
            </b-row>
          </div>
        </template>
        <div class="table-responsive">
          <table class="table table-bordered   text-nowrap">
            <tbody>
              <tr>
                <td>
                  <p class="mb-0"><strong>Meeting</strong></p>
                  <p class="mb-0 text-muted">07-07-2020</p>
                </td>
                <td><span class="badge bg-success">Done</span></td>
              </tr>

              <tr>
                <td>
                  <p class="mb-0"><strong>Meeting</strong></p>
                  <p class="mb-0 text-muted">07-07-2020</p>
                </td>
                <td><span class="badge bg-danger">Cancelled</span></td>
              </tr>

            </tbody>
          </table>
        </div>
      </b-card>
    </div>
    <bo-footer></bo-footer>
  </div>
</template>
<script>
import chartDatas from '@/dummies/chartDatas'
import tableDatas from '@/dummies/tableDatas'
import {
  use
} from "echarts/core";
import {
  CanvasRenderer
} from "echarts/renderers";
import {
  LineChart,
} from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DataZoomComponent,
} from "echarts/components";
import VChart from 'vue-echarts'
import GlobalVue from '../../libs/Global.vue';

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DataZoomComponent,
])

export default {
  extends: GlobalVue,
  components: {
    VChart,
  },
  data() {
    return {
      dateRange: {},
      leadReportOptions: chartDatas.leadReportOptions,
      leadSummaryFields: tableDatas.leadSummaryFields,
      leadSummaryDatas: tableDatas.leadSummaryDatas,
      leadSummaryPage: 1,
      closingFields: tableDatas.closingFields,
      closingDatas: tableDatas.closingDatas,
      closingPage: 1,
      lead1: '',
      timeOptions: [
        { text: '-- All Time --', value: '' },
        { text: 'Week', value: 'opt1' },
        { text: 'Day', value: 'opt2' },
      ],
      selTime: ''
    }
  }
}
</script>
